<template>
  <div class="home">
    <div class="app-container">
      <div id="lightgallery">
        <a class="gallery-item" v-for="url in imageList" :href="url">
          <img class="gallery_img" :src="url+'?x-oss-process=image/resize,h_400,m_lfit'"/>
          <!--          <img class="img-fluid"  :src="url+'?x-oss-process=image/resize,w_200,m_lfit'">-->
        </a>
      </div>
    </div>
  </div>
</template>

<script>

import HelloWorld from '@/components/HelloWorld.vue'
import 'lightgallery.js'
import 'lightgallery.js/dist/css/lightgallery.css'
import lgZoom from "lightgallery/plugins/zoom";
import lgThumbnail from "lightgallery/plugins/thumbnail";

export default {
  name: 'HomeView',
  components: {
    HelloWorld
  },
  data() {
    return {
      imageList: [
        "https://hcs-gallery.oss-cn-beijing.aliyuncs.com/v1/11_.jpg",
        "https://hcs-gallery.oss-cn-beijing.aliyuncs.com/v1/1_.jpg",
        "https://hcs-gallery.oss-cn-beijing.aliyuncs.com/v1/2_.jpg",
        "https://hcs-gallery.oss-cn-beijing.aliyuncs.com/v1/3_.jpg",
        "https://hcs-gallery.oss-cn-beijing.aliyuncs.com/v1/4_.jpg",
        "https://hcs-gallery.oss-cn-beijing.aliyuncs.com/v1/5_.jpg",
        // "https://hcs-gallery.oss-cn-beijing.aliyuncs.com/v1/19_.jpg",
        "https://hcs-gallery.oss-cn-beijing.aliyuncs.com/v1/7_.jpg",
        "https://hcs-gallery.oss-cn-beijing.aliyuncs.com/v1/8_.jpg",
        "https://hcs-gallery.oss-cn-beijing.aliyuncs.com/v1/9_.jpg",
        "https://hcs-gallery.oss-cn-beijing.aliyuncs.com/v1/10_.jpg",
        "https://hcs-gallery.oss-cn-beijing.aliyuncs.com/v1/12_.jpg",
        "https://hcs-gallery.oss-cn-beijing.aliyuncs.com/v1/13_.jpg",
        "https://hcs-gallery.oss-cn-beijing.aliyuncs.com/v1/15_.jpg",
        "https://hcs-gallery.oss-cn-beijing.aliyuncs.com/v1/17_.jpg",
        "https://hcs-gallery.oss-cn-beijing.aliyuncs.com/v1/18_.jpg",
      ]
    }
  },
  mounted() {
    const el = document.getElementById('lightgallery')
    window.lightGallery(el, {
      // animateThumb: true,
      // zoomFromOrigin: false,
      // allowMediaOverlap: true,
      // toggleThumb: true,
      // thumbnail: true,
      // speed: 500,
      // controls: false,
      // plugins: [lgZoom, lgThumbnail]
    })
  }
}
</script>
<style scoped>
.gallery-item {
  padding: 5px;
  /*width: 300px;*/
}

.gallery_img {
  /*max-width: 50vw;*/
  /*width: 300px;*/
}

</style>