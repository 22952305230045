import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

// import lightgallery from 'lightgallery'
// import 'lightgallery/css/lightgallery.css'



// Vue.use(lightgallery)

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
